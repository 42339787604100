import {
  inputClasses,
  inputLabelClasses,
  StandardTextFieldProps,
  styled,
  TextField,
} from '@mui/material'
import * as React from 'react'
import {pxToRem} from '../../../theme'

export interface TextInputProps extends StandardTextFieldProps {}

const StyledTextField = styled(TextField)(({theme}) => ({
  [`& .${inputLabelClasses.root}`]: {
    color: theme.palette.common.white,
    fontSize: pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
  },
  [`& .${inputClasses.input}, & .${inputClasses.inputMultiline}`]: {
    borderRadius: 10,
    padding: theme.spacing(2),
    border: '1px solid transparent',
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.background.paper
        : theme.palette.common.white,
  },
  [`& .${inputClasses.inputMultiline}`]: {
    height: `100px !important`,
  },
  [`& ${inputClasses.input}.${inputClasses.error}`]: {
    borderRadius: 10,
    border: `1px solid ${theme.palette.error.main}`,
  },
}))

export const TextInput: React.FC<TextInputProps> = ({
  InputLabelProps,
  InputProps,
  ...props
}) => {
  return (
    <StyledTextField
      variant="standard"
      InputProps={{...InputProps, disableUnderline: true}}
      InputLabelProps={{...InputLabelProps, shrink: true}}
      {...props}
    />
  )
}

export default TextInput

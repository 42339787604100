import {useLocation} from '@reach/router'
import {initializeAndTrack} from 'gatsby-plugin-gdpr-cookies'
import * as React from 'react'
import CookieConsentBanner, {
  CookieConsentBannerProps,
} from '../../components/atoms/CookieConsentBanner'

const CookieConsentContext = React.createContext(null)

const COOKIE_STORAGE_KEY = `cookie-status`

export function CookieConsentProvider({
  title,
  body,
  acceptLabel,
  rejectLabel,
  linkLabel,
  language,
  children,
}: React.PropsWithChildren<
  Pick<
    CookieConsentBannerProps,
    'title' | 'body' | 'acceptLabel' | 'rejectLabel' | 'linkLabel'
  > & {language: string}
>) {
  const location = useLocation()
  const [cookieStatus, setCookieStatus] = React.useState(() => {
    if (
      typeof window !== 'undefined' &&
      window.localStorage.getItem(COOKIE_STORAGE_KEY)
    ) {
      return window.localStorage.getItem(COOKIE_STORAGE_KEY)
    }

    return undefined
  })

  React.useEffect(() => {
    if (cookieStatus === `accepted`) {
      if (typeof document !== 'undefined') {
        document.cookie = `gatsby-gdpr-google-tagmanager=true;path=/`
      }

      initializeAndTrack(location)
    } else {
      if (typeof document !== 'undefined') {
        document.cookie = `gatsby-gdpr-google-tagmanager=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/`
      }
    }
  }, [cookieStatus, location])

  function handleAccept() {
    setCookieStatus(`accepted`)

    if (typeof window !== 'undefined') {
      window.localStorage.setItem(COOKIE_STORAGE_KEY, `accepted`)
    }
  }

  function handleReject() {
    setCookieStatus(`rejected`)

    if (typeof window !== 'undefined') {
      window.localStorage.setItem(COOKIE_STORAGE_KEY, `rejected`)
    }
  }

  return (
    <CookieConsentContext.Provider value={null}>
      {!cookieStatus && (
        <CookieConsentBanner
          language={language}
          title={title}
          body={body}
          acceptLabel={acceptLabel}
          rejectLabel={rejectLabel}
          linkLabel={linkLabel}
          onAccept={handleAccept}
          onReject={handleReject}
        />
      )}

      {children}
    </CookieConsentContext.Provider>
  )
}

export default CookieConsentContext

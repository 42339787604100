import {Box, Button, Container, styled, Typography} from '@mui/material'
import * as React from 'react'
import {RouteLink} from '../RouteLink'

export interface CookieConsentBannerProps {
  title: string
  body: string
  acceptLabel: string
  rejectLabel: string
  linkLabel: string
  language: string
  onAccept?: () => void
  onReject?: () => void
}

const StyledRouteLink = styled(RouteLink)(({theme}) => ({
  color: theme.palette.primary.main,
  padding: 0,
  fontSize: 12,
  [theme.breakpoints.up('sm')]: {
    fontSize: 14,
  },
}))

function CookieConsentBanner({
  title,
  body,
  acceptLabel,
  rejectLabel,
  linkLabel,
  language,
  onAccept,
  onReject,
}: CookieConsentBannerProps) {
  return (
    <Box
      className="cookie-consent-banner"
      sx={{
        position: `fixed`,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: (theme) => theme.zIndex.appBar - 1,
        backgroundColor: `#46444f`,
      }}
    >
      <Container
        sx={{
          display: `grid`,
          gridAutoFlow: {xs: `row`, lg: `column`},
          justifyContent: `space-between`,
          justifyItems: {xs: `center`, lg: `initial`},
          alignItems: `center`,
          color: (theme) => theme.palette.common.white,
          paddingTop: (theme) => theme.spacing(2),
          paddingBottom: (theme) => theme.spacing(2),
          gap: {xs: 2, lg: 0},
        }}
      >
        <Box
          sx={{
            display: `grid`,
            gap: (theme) => theme.spacing(0.5),
            justifyItems: `start`,
          }}
        >
          <Typography variant="h4" sx={{fontSize: {xs: 14, sm: 22}}}>
            {title}
          </Typography>

          <Typography sx={{fontSize: {xs: 12, sm: 14}}}>{body}</Typography>

          <StyledRouteLink
            navLink
            disableActive
            to={`/${language}/cookies-and-privacy-policy`}
          >
            {linkLabel}
          </StyledRouteLink>
        </Box>

        <Box
          sx={{
            display: `grid`,
            maxWidth: 320,
            width: `100%`,
            gridAutoFlow: {xs: 'row', sm: 'column'},
            gap: {xs: 1, sm: 0.5},
          }}
        >
          <Button
            sx={{
              textTransform: `none`,
              width: {xs: '100%', sm: 145},
              fontWeight: (theme) => theme.typography.fontWeightBold,
            }}
            color="primary"
            variant="contained"
            onClick={onAccept}
            disableElevation
          >
            {acceptLabel}
          </Button>

          <Button
            sx={{
              textTransform: `none`,
              width: {xs: '100%', sm: 145},
              fontWeight: (theme) => theme.typography.fontWeightBold,
            }}
            color="primary"
            variant="outlined"
            onClick={onReject}
          >
            {rejectLabel}
          </Button>
        </Box>
      </Container>
    </Box>
  )
}

export default CookieConsentBanner

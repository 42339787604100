import {Box, BoxProps, Container, Grid, styled} from '@mui/material'
import {GatsbyImageFixedProps, GatsbyImageFluidProps} from 'gatsby-image'
import Image from 'gatsby-image/withIEPolyfill'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import * as React from 'react'
import useMenuHighlighter from '../../../lib/useMenuHighlighter'
import {Headline, Link, Paragraph} from '../../atoms'

export interface IntroSectionTemplateProps extends BoxProps {
  image: GatsbyImageFluidProps
  appStoreBadge: GatsbyImageFixedProps
  googlePlayBadge: GatsbyImageFixedProps
  appStoreUrl?: string
  googlePlayUrl?: string
}

const StyledContentContainer = styled(Box)(({theme}) => ({
  padding: theme.spacing(3, 0, 8),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(8, 0, 12),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(9, 0, 15),
  },
}))

const MobileTitleGrid = styled(Grid)(({theme}) => ({
  display: 'block',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}))

const DesktopTitleContainer = styled(Box)(({theme}) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}))

const StyledTextGrid = styled(Grid)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('sm')]: {
    paddingRight: '3%',
  },
}))

const StyledStoreLinkContainer = styled(Box)(({theme}) => ({
  display: 'grid',
  gridAutoFlow: 'column',
  justifyContent: 'center',
  gap: theme.spacing(1.5),
  [theme.breakpoints.up('md')]: {
    justifyContent: 'end',
  },
}))

const StyledImage = styled(Image)(({theme}) => ({
  maxWidth: 200,
  margin: theme.spacing(4, 'auto'),
  [theme.breakpoints.up('sm')]: {
    maxWidth: 285,
    margin: '0 auto',
  },
}))

export const IntroSectionTemplate: React.FC<IntroSectionTemplateProps> = ({
  image,
  appStoreBadge,
  googlePlayBadge,
  appStoreUrl,
  googlePlayUrl,
  ...props
}) => {
  const {t} = useTranslation()
  const containerRef = React.useRef<HTMLDivElement>()

  useMenuHighlighter({target: containerRef.current, sectionName: 'intro'})

  return (
    <StyledContentContainer ref={containerRef} component="section" {...props}>
      <Container>
        <Grid container>
          <MobileTitleGrid item sm={12} xs={12}>
            <Headline variant="h1" color="primary">
              {t('home.title')}
            </Headline>

            <Headline
              variant="h4"
              component="h2"
              color="textSecondary"
              sx={{
                fontWeight: (theme) => theme.typography.fontWeightMedium,
                fontStyle: 'italic',
                margin: (theme) => theme.spacing(1.5, 0, 4),
              }}
            >
              {t('home.description')}
            </Headline>
          </MobileTitleGrid>

          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            sx={{
              transition: 'transform 0.1s ease 0',
            }}
          >
            <StyledImage fluid={image.fluid} alt={t('home.image.phone.alt')} />
          </Grid>

          <StyledTextGrid item md={6} sm={12} xs={12}>
            <Box sx={{flexBasis: 'auto'}}>
              <DesktopTitleContainer>
                <Headline variant="h1" color="primary">
                  {t('home.title')}
                </Headline>

                <Headline
                  variant="h4"
                  component="h2"
                  color="textSecondary"
                  sx={{
                    fontWeight: (theme) => theme.typography.fontWeightMedium,
                    fontStyle: 'italic',
                    margin: (theme) => theme.spacing(1.5, 0, 4),
                  }}
                >
                  {t('home.description')}
                </Headline>
              </DesktopTitleContainer>

              <Paragraph>
                <strong>{t('home.productDescription.firstParagraph')}</strong>
              </Paragraph>

              <Paragraph>
                {t('home.productDescription.secondParagraph')}
              </Paragraph>

              <Paragraph>
                {t('home.productDescription.thirdParagraph')}
              </Paragraph>
            </Box>

            {(appStoreUrl || googlePlayUrl) && (
              <StyledStoreLinkContainer>
                {googlePlayUrl && (
                  <Link
                    href={googlePlayUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    padding="none"
                  >
                    <Image
                      Tag="span"
                      fixed={googlePlayBadge.fixed}
                      alt={t('home.image.googlePlay.alt')}
                    />
                  </Link>
                )}

                {appStoreUrl && (
                  <Link
                    href={appStoreUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    padding="none"
                  >
                    <Image
                      Tag="span"
                      fixed={appStoreBadge.fixed}
                      alt={t('home.image.appStore.alt')}
                    />
                  </Link>
                )}
              </StyledStoreLinkContainer>
            )}
          </StyledTextGrid>
        </Grid>
      </Container>
    </StyledContentContainer>
  )
}

export default IntroSectionTemplate

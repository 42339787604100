import {Grid, GridProps, styled} from '@mui/material'
import {useI18next} from 'gatsby-plugin-react-i18next'
import * as React from 'react'
import {ChevronLeftIcon, IconButton, RouteLink} from '../../atoms'

export interface SidebarGridProps extends Omit<GridProps, 'item'> {
  component?: string
}

const StyledContainerGrid = styled(Grid)(({theme}) => ({
  marginBottom: theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    marginBottom: 0,
  },
}))

const StyledChevronLeftIcon = styled(ChevronLeftIcon)(({theme}) => ({
  color: theme.palette.text.primary,
}))

const StyledRouteLink = styled(RouteLink)``

export const SidebarGrid: React.FC<SidebarGridProps> = ({
  children,
  ...props
}) => {
  const {defaultLanguage, language} = useI18next()
  return (
    <StyledContainerGrid item {...props}>
      <StyledRouteLink
        to={defaultLanguage === language ? '/' : `/${language}`}
        sx={[
          {
            display: 'inline-block',
            marginBottom: {xs: 2, sm: 4, md: 10},
          },
          !children && {
            marginBottom: '0 !important',
          },
        ]}
        hrefLang={language}
        padding="no-horizontal"
      >
        <IconButton component="span">
          <StyledChevronLeftIcon color="primary" />
        </IconButton>
      </StyledRouteLink>

      {children}
    </StyledContainerGrid>
  )
}

export default SidebarGrid

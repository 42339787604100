import {
  getLinkUtilityClass,
  Link as MaterialLink,
  LinkProps as MaterialLinkProps,
  styled,
} from '@mui/material'
import clsx from 'clsx'
import * as React from 'react'
import {pxToRem} from '../../../theme'

export interface LinkProps extends MaterialLinkProps {
  active?: boolean
  component?: React.ElementType
  type?: 'default' | 'button'
  padding?: 'default' | 'none'
}

export const StyledLink = styled(MaterialLink)(({theme}) => ({
  color: theme.palette.text.primary,
  fontSize: pxToRem(14),
  fontFamily: '"Exo 2", Helvetica, Arial, sans-serif',
  cursor: 'pointer',
  borderRadius: 8,
  padding: theme.spacing(0.75, 1.5),
  border: `1px solid transparent`,
  transition: 'border-color 0.25s ease-in-out',
  [`&.${getLinkUtilityClass(`active`)}`]: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    border: `1px solid ${theme.palette.primary.main}`,
  },
}))

export const Link: React.FC<LinkProps> = ({
  active,
  type = 'default',
  padding = 'default',
  sx,
  ...props
}) => {
  if (type === 'button') {
    return (
      <StyledLink
        underline="hover"
        className={clsx(active && getLinkUtilityClass('active'))}
        sx={[
          {
            backgroundColor: (theme) => theme.palette.primary.main,
            color: (theme) => theme.palette.text.disabled,
            border: (theme) => `1px solid ${theme.palette.primary.main}`,
            padding: padding === 'none' ? '0 !important' : undefined,
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        {...props}
      />
    )
  }

  if (active) {
    return (
      <StyledLink
        underline="hover"
        className={clsx(active && getLinkUtilityClass('active'))}
        sx={[
          {
            color: (theme) => theme.palette.primary.main,
            fontWeight: (theme) => theme.typography.fontWeightBold,
            border: (theme) => `1px solid ${theme.palette.primary.main}`,
            padding: padding === 'none' ? '0 !important' : undefined,
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        {...props}
      />
    )
  }

  return (
    <StyledLink
      underline="hover"
      className={clsx(active && getLinkUtilityClass('active'))}
      sx={[
        {padding: padding === 'none' ? '0 !important' : ''},
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    />
  )
}

export default Link

import SEO from '@hypesync/seo'
import {Box, Button, styled} from '@mui/material'
import {Field, Form, Formik} from 'formik'
import * as React from 'react'
import * as Yup from 'yup'
import {useDevelopmentPassword} from '../../../context'
import {TextInput} from '../../atoms'

export interface PasswordFormProps {}

interface PasswordFormValues {
  password: string
}

const StyledContainer = styled(Box)(({theme}) => ({
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.common.black
      : theme.palette.background.paper,
  '& > form': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *:first-child': {
      width: 250,
    },
    '& > *:last-child': {
      width: 200,
    },
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}))

const validationSchema = Yup.object().shape({
  password: Yup.string().nullable().required('This field is required'),
})

const initialValues: PasswordFormValues = {
  password: '',
}

export const PasswordForm: React.FC<PasswordFormProps> = () => {
  const [isCorrect, setIsCorrect] = React.useState<boolean>()
  const {onSubmitPassword} = useDevelopmentPassword()

  function handleSubmit(values: PasswordFormValues) {
    const isCorrectResponse = onSubmitPassword(values.password)

    // note: otherwise we'd entirely remove this component from the DOM
    if (!isCorrectResponse) {
      setIsCorrect(isCorrectResponse)
    }
  }

  return (
    <StyledContainer>
      <Formik
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={initialValues}
      >
        <Form>
          <Field
            as={TextInput}
            type="password"
            name="password"
            label="Password"
            placeholder="Development mode password"
            required
            error={!isCorrect}
            helperText={!isCorrect ? 'Invalid password' : undefined}
          />

          <Button
            type="submit"
            color="primary"
            variant="contained"
            disableElevation
          >
            Sign in
          </Button>
        </Form>
      </Formik>

      <SEO title="Sign in" siteName="Finastic" />
    </StyledContainer>
  )
}

export default PasswordForm

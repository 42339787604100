import {SvgIcon, SvgIconProps} from '@mui/material'
import * as React from 'react'

export const ChevronLeftIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6705 4.31381C15.1098 4.73223 15.1098 5.41062 14.6705 5.82904L8.71599 11.5L14.6705 17.171C15.1098 17.5894 15.1098 18.2678 14.6705 18.6862C14.2312 19.1046 13.5188 19.1046 13.0795 18.6862L6.3295 12.2576C5.89017 11.8392 5.89017 11.1608 6.3295 10.7424L13.0795 4.31381C13.5188 3.8954 14.2312 3.8954 14.6705 4.31381Z"
    />
  </SvgIcon>
)

export default ChevronLeftIcon

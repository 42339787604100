import {Button, ButtonProps, styled} from '@mui/material'
import * as React from 'react'

const StyledIconButton = styled(Button)(({theme}) => ({
  minWidth: 'initial',
  minHeight: 'initial',
  width: 27,
  height: 27,
  borderRadius: 4,
  border: `2px solid ${theme.palette.divider}`,
  color: theme.palette.text.primary,
}))

export interface IconButtonProps extends ButtonProps {
  component?: string
}

export const IconButton: React.FC<IconButtonProps> = (props) => {
  return <StyledIconButton {...props} />
}

export default IconButton

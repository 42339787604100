import {styled, Typography, TypographyProps} from '@mui/material'
import * as React from 'react'
import {pxToRem} from '../../../theme'

const StyledSectionTitle = styled(Typography)(({theme}) => ({
  maxWidth: 470,
  fontSize: pxToRem(42),
  lineHeight: '52px',
  marginBottom: theme.spacing(4),
}))

export interface SectionTitleProps extends TypographyProps {
  component?: string
}

export const SectionTitle: React.FC<SectionTitleProps> = (props) => {
  return <StyledSectionTitle {...props} />
}

export default SectionTitle

import {
  alpha,
  Button,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  styled,
  Typography,
} from '@mui/material'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import * as React from 'react'
import {languageMap} from '../../../constants'
import {useWidth} from '../../../lib'
import {Link, RouteLink} from '../../atoms'

export interface NavigationProps {
  loginRegistrationUrl: string
  highlightedIndicator?: string
  subpage?: boolean
  onLinkClick?: VoidFunction
  variant?: 'header' | 'footer'
}

const StyledFlagIcon = styled('img')(({theme}) => ({
  width: 32,
  height: 32,
  borderRadius: 16,
  objectFit: 'cover',
  [theme.breakpoints.up('lg')]: {
    width: 28,
    height: 28,
    border: '2px solid rgba(255, 255, 255, 0.2)',
    borderRadius: 14,
  },
}))

const StyledRouteLink = styled(RouteLink)({})

export const Navigation: React.FC<NavigationProps> = ({
  loginRegistrationUrl,
  highlightedIndicator,
  onLinkClick,
  subpage,
  variant = 'header',
}) => {
  const {t} = useTranslation()
  const {language, changeLanguage} = useI18next()
  const breakpoint = useWidth()
  const isMobile = ['xs', 'sm'].includes(breakpoint)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [
    loginRegistrationUrlWithLang,
    setLoginRegistrationUrlWithLang,
  ] = React.useState(() => loginRegistrationUrl)

  React.useEffect(() => {
    const urlWithLang = new URL(loginRegistrationUrl)
    urlWithLang.searchParams.set('lang', language)
    setLoginRegistrationUrlWithLang(urlWithLang.toString())
  }, [language])

  function handleClick(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  async function openLanguage(language: string) {
    handleClose()
    await changeLanguage(language)

    if (typeof window !== 'undefined') {
      window.localStorage.removeItem('gatsby-i18next-language')
    }
  }

  const menuItems: Array<{name: string; id: string}> = [
    {name: t('navigation.whatIsFinastic'), id: 'intro'},
    {name: t('navigation.benefits'), id: 'advantages'},
    {name: t('navigation.howItWorks'), id: 'how-it-works'},
    {name: t('navigation.aboutUs'), id: 'about-us'},
    {name: t('navigation.contact'), id: 'contact'},
  ]

  function scrollToElement(id: string) {
    if (onLinkClick) {
      onLinkClick()
    }

    const targetElement = document.getElementById(id)

    if (window && targetElement) {
      const targetTopPosition = targetElement.offsetTop - 56

      window.scrollTo({
        top:
          id === 'how-it-works' && !isMobile
            ? targetTopPosition + 70
            : targetTopPosition,
        behavior: 'smooth',
      })
    }
  }

  return (
    <>
      {subpage
        ? menuItems.map(({id, name}) => (
            <StyledRouteLink
              to={`/${language}/#${id}`}
              navLink
              hrefLang={language}
              sx={[
                variant === 'footer' && {
                  color: (theme) => theme.palette.common.white,
                  paddingLeft: 0,
                  paddingRight: 0,
                },
              ]}
              key={id}
            >
              {name}
            </StyledRouteLink>
          ))
        : menuItems.map(({id, name}) => (
            <Link
              active={highlightedIndicator === id}
              onClick={() => scrollToElement(id)}
              hrefLang={language}
              key={id}
              underline="hover"
              sx={[
                variant === 'footer' && {
                  color: (theme) => theme.palette.common.white,
                  paddingLeft: 0,
                  paddingRight: 0,
                },
              ]}
            >
              {name}
            </Link>
          ))}

      <StyledRouteLink
        navLink
        to={`/${language}/faq/`}
        hrefLang={language}
        disableActive={variant === 'footer'}
        sx={[
          variant === 'footer' && {
            color: (theme) => theme.palette.common.white,
            paddingLeft: 0,
            paddingRight: 0,
          },
        ]}
      >
        {t('navigation.faq')}
      </StyledRouteLink>

      <Link
        href={loginRegistrationUrlWithLang}
        type={variant === 'footer' ? 'default' : 'button'}
        onClick={onLinkClick}
        sx={[
          variant === 'footer' && {
            color: (theme) => theme.palette.common.white,
            paddingLeft: 0,
            paddingRight: 0,
          },
        ]}
      >
        {t('navigation.loginOrRegister')}
      </Link>

      {variant === 'header' && (
        <>
          {isMobile ? (
            <Button
              id="language-selector-button"
              aria-controls="language-selector-menu"
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              disableRipple
              sx={{
                ':hover': {
                  backgroundColor: (theme) =>
                    alpha(theme.palette.common.white, 0.05),
                },
                color: (theme) => theme.palette.common.white,
                margin: {
                  xs: `8px auto 0`,
                  lg: '0 0 0 8px',
                },
                padding: (theme) => theme.spacing(0.5),
              }}
            >
              {languageMap[language] && (
                <>
                  <StyledFlagIcon
                    width={44}
                    height={44}
                    src={languageMap[language].iconPath}
                    alt={languageMap[language].title}
                  />

                  <Typography
                    sx={{
                      textTransform: 'none',
                      marginLeft: (theme) => theme.spacing(),
                    }}
                  >
                    {languageMap[language].title}
                  </Typography>
                </>
              )}
            </Button>
          ) : (
            <IconButton
              id="language-selector-button"
              aria-controls="language-selector-menu"
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              sx={{
                margin: {
                  xs: `8px auto 0`,
                  lg: 1,
                },
                padding: (theme) => theme.spacing(0.5),
              }}
            >
              {languageMap[language] && (
                <StyledFlagIcon
                  width={44}
                  height={44}
                  src={languageMap[language].iconPath}
                  alt={languageMap[language].title}
                />
              )}
            </IconButton>
          )}

          <Menu
            id="language-selector-menu"
            aria-labelledby="language-selector-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {Object.keys(languageMap).map((language) => (
              <MenuItem onClick={() => openLanguage(language)} key={language}>
                <ListItemIcon sx={{minWidth: 40}}>
                  <StyledFlagIcon
                    width={44}
                    height={44}
                    src={languageMap[language].iconPath}
                    alt={languageMap[language].title}
                  />
                </ListItemIcon>

                <Typography>{languageMap[language].title}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </>
  )
}
export default Navigation

import englishFlag from '../images/english-flag.svg'
import polishFlag from '../images/polish-flag.svg'
import romanianFlag from '../images/romanian-flag.svg'

export const SCROLL_TRIGGER_DESKTOP_THRESHOLD = 250
export const SCROLL_TRIGGER_MOBILE_THRESHOLD = 350
export const FORMSPREE_API_KEY = process.env.GATSBY_FORMSPREE_API_KEY

// add different FORMSPREE_API_KEYs here

export interface LanguageSettings {
  title: string
  iconPath: string
  formspreeAPIKey: string
}

export const languageMap: Record<string, LanguageSettings> = {
  en: {
    title: 'English',
    iconPath: englishFlag,
    formspreeAPIKey:
      process.env[`GATSBY_FORMSPREE_API_KEY_EN`] || FORMSPREE_API_KEY,
  },
  ro: {
    title: 'Română',
    iconPath: romanianFlag,
    formspreeAPIKey:
      process.env[`GATSBY_FORMSPREE_API_KEY_RO`] || FORMSPREE_API_KEY,
  },
  pl: {
    title: 'Polski',
    iconPath: polishFlag,
    formspreeAPIKey:
      process.env[`GATSBY_FORMSPREE_API_KEY_PL`] || FORMSPREE_API_KEY,
  },
}

import * as React from 'react'
import Alert from '../../components/atoms/Alert'

interface Alert {
  text: string
  type: 'success' | 'error'
}

const AlertContext = React.createContext<{
  setAlert: React.Dispatch<React.SetStateAction<Alert>>
  clearAlert: VoidFunction
}>(undefined)

export const AlertProvider: React.FC = ({children}) => {
  const [alert, setAlert] = React.useState<Alert>()

  function clearAlert() {
    setAlert(undefined)
  }

  // TODO: Make sure this can be configured via useAlert hook
  function setAlertAndRemoveAfterTimeout(alert: Alert) {
    setAlert(alert)

    setTimeout(() => {
      clearAlert()
    }, 5000)
  }

  return (
    <AlertContext.Provider
      value={{setAlert: setAlertAndRemoveAfterTimeout, clearAlert}}
    >
      {alert && <Alert variant={alert.type}>{alert.text}</Alert>}

      {children}
    </AlertContext.Provider>
  )
}

export function useAlert() {
  const context = React.useContext(AlertContext)

  if (!context) {
    throw new Error('`useAlert` must be used within an `AlertContext`')
  }

  return context
}

export default AlertContext

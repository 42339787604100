import {Box, BoxProps, styled} from '@mui/material'
import * as React from 'react'
import {pxToRem} from '../../../theme'

export type ParagraphProps = Omit<BoxProps, 'component'>

const StyledBox = styled(Box)(({theme}) => ({
  marginBottom: theme.spacing(3),
  fontSize: pxToRem(14),
  lineHeight: '150%',
}))

export const Paragraph: React.FC<ParagraphProps> = (props) => {
  return <StyledBox component="p" {...props} />
}

export default Paragraph

import * as React from 'react'
import {useWidth} from '.'
import {
  SCROLL_TRIGGER_DESKTOP_THRESHOLD,
  SCROLL_TRIGGER_MOBILE_THRESHOLD,
} from '../constants'
import {AvailableSection, useSectionIndicator} from '../context'

export interface MenuHighlighterProps {
  target: HTMLElement
  sectionName: AvailableSection
}

function useMenuHighlighter({target, sectionName}: MenuHighlighterProps) {
  const breakpoint = useWidth()
  const isMobile = ['xs', 'sm'].includes(breakpoint)
  const scrollTriggerThreshold = isMobile
    ? SCROLL_TRIGGER_MOBILE_THRESHOLD
    : SCROLL_TRIGGER_DESKTOP_THRESHOLD
  const [, setHighlightedSection] = useSectionIndicator()

  function setHighlightBasedOnScrollPosition(windowObj?: Window) {
    const windowObject = windowObj || window

    if (windowObject && target) {
      const startThreshold = target.offsetTop - scrollTriggerThreshold
      const endThreshold =
        target.offsetTop + target.clientHeight - scrollTriggerThreshold

      if (
        windowObject.scrollY > startThreshold &&
        windowObject.scrollY < endThreshold
      ) {
        setHighlightedSection(sectionName)
      }
    }
  }

  function setSectionHighlightedOnScroll(event: Event) {
    if (event.currentTarget instanceof Window) {
      const {currentTarget: window} = event

      setHighlightBasedOnScrollPosition(window)
    }
  }

  React.useEffect(() => {
    setHighlightBasedOnScrollPosition(window)
  }, [setHighlightBasedOnScrollPosition])

  React.useEffect(() => {
    if (target && window) {
      window.addEventListener('scroll', setSectionHighlightedOnScroll)
    }

    return () => {
      if (window) {
        window.removeEventListener('scroll', setSectionHighlightedOnScroll)
      }
    }
  }, [target])
}

export default useMenuHighlighter

import {BoxProps, Grid, styled, useMediaQuery} from '@mui/material'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import * as React from 'react'
import cardAnimation from '../../../animations/card.json'
import envelopeAnimation from '../../../animations/envelope.json'
import magnifierAnimation from '../../../animations/magnifier.json'
import sliderAnimation from '../../../animations/slider.json'
import WaveLeftDarkImage from '../../../images/wave-left-dark.svg'
import WaveLeftLightImage from '../../../images/wave-left-light.svg'
import WaveRightDarkImage from '../../../images/wave-right-dark.svg'
import WaveRightLightImage from '../../../images/wave-right-light.svg'
import {useWidth} from '../../../lib'
import useMenuHighlighter from '../../../lib/useMenuHighlighter'
import {Section, SectionTitle} from '../../atoms'
import ShowcaseCard from '../ShowcaseCard'

export interface AdvantagesSectionTemplateProps extends BoxProps {}

const StyledSection = styled(Section)(({theme}) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.common.black
      : theme.palette.primary.main,
  paddingBottom: theme.spacing(18),
  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(20),
  },
}))

const StyledCardGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
})

const StyledDefaultWave = styled(`img`)(({theme}) => ({
  width: '300%',
  height: 'auto',
  [theme.breakpoints.up('sm')]: {
    width: '200%',
  },
  [theme.breakpoints.up('md')]: {
    width: '150%',
  },
  [theme.breakpoints.up('lg')]: {
    width: '100%',
  },
}))

const StyledLeftWave = styled(StyledDefaultWave)(({theme}) => ({
  marginLeft: '-15%',
  marginBottom: theme.spacing(-1.25),
  [theme.breakpoints.up('sm')]: {
    marginLeft: '-15%',
  },
  [theme.breakpoints.up('md')]: {
    marginLeft: '-5%',
  },
  [theme.breakpoints.up('lg')]: {
    marginLeft: 0,
  },
}))

const StyledRightWave = styled(StyledDefaultWave)(({theme}) => ({
  position: 'absolute',
  bottom: -10,
  right: 0,
  marginRight: '-25%',
  [theme.breakpoints.up('sm')]: {
    marginRight: '-15%',
  },
  [theme.breakpoints.up('md')]: {
    marginRight: '-5%',
  },
  [theme.breakpoints.up('lg')]: {
    marginRight: 0,
  },
}))

const animations = {
  card: cardAnimation,
  envelope: envelopeAnimation,
  magnifier: magnifierAnimation,
  slider: sliderAnimation,
}

export const AdvantagesSectionTemplate: React.FC<AdvantagesSectionTemplateProps> = ({
  ...props
}) => {
  const breakpoint = useWidth()
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const containerRef = React.useRef<HTMLDivElement>()
  const waveLeftImagePath = prefersDarkMode
    ? WaveLeftDarkImage
    : WaveLeftLightImage
  const waveRightImagePath = prefersDarkMode
    ? WaveRightDarkImage
    : WaveRightLightImage
  const {t} = useTranslation()

  useMenuHighlighter({
    target: containerRef.current,
    sectionName: 'advantages',
  })

  function getGridSpacingForScreenSize() {
    switch (breakpoint) {
      case 'md':
        return 6
      case 'sm':
        return 3
      case 'xs':
        return 3
      default:
        return 2
    }
  }

  return (
    <>
      <StyledLeftWave
        src={waveLeftImagePath}
        alt={t('home.image.wave.alt')}
        width={1920}
        height={135}
      />

      <StyledSection
        style={{position: 'relative'}}
        component="section"
        ref={containerRef}
        {...props}
      >
        <SectionTitle variant="h2">{t('home.benefits.title')}</SectionTitle>

        <Grid container spacing={getGridSpacingForScreenSize()}>
          <StyledCardGrid item xs={12} sm={6} md={6} lg={3}>
            <ShowcaseCard
              animationData={animations.magnifier}
              animationName="magnifier-animation"
              title={t('home.benefits.finances.title')}
              description={t('home.benefits.finances.description')}
              titleAlignment="center"
            />
          </StyledCardGrid>

          <StyledCardGrid item xs={12} sm={6} md={6} lg={3}>
            <ShowcaseCard
              animationData={animations.envelope}
              animationName="envelope-animation"
              title={t('home.benefits.communication.title')}
              description={t('home.benefits.communication.description')}
              titleAlignment="center"
            />
          </StyledCardGrid>

          <StyledCardGrid item xs={12} sm={6} md={6} lg={3}>
            <ShowcaseCard
              animationData={animations.card}
              animationName="card-animation"
              title={t('home.benefits.payments.title')}
              description={t('home.benefits.payments.description')}
              titleAlignment="center"
            />
          </StyledCardGrid>

          <StyledCardGrid item xs={12} sm={6} md={6} lg={3}>
            <ShowcaseCard
              animationData={animations.slider}
              animationName="slider-animation"
              title={t('home.benefits.repayments.title')}
              description={t('home.benefits.repayments.description')}
              titleAlignment="center"
            />
          </StyledCardGrid>
        </Grid>

        <StyledRightWave
          src={waveRightImagePath}
          alt={t('home.image.wave.alt')}
          width={1920}
          height={135}
        />
      </StyledSection>
    </>
  )
}

export default AdvantagesSectionTemplate

import * as React from 'react'

export type AvailableSection =
  | 'intro'
  | 'advantages'
  | 'how-it-works'
  | 'about-us'
  | 'contact'

const SectionIndicatorContext = React.createContext<
  [AvailableSection, React.Dispatch<React.SetStateAction<AvailableSection>>]
>(undefined)

export const SectionIndicatorProvider: React.FC = ({children}) => {
  const highlightedSectionState = React.useState<AvailableSection>()

  return (
    <SectionIndicatorContext.Provider value={highlightedSectionState}>
      {children}
    </SectionIndicatorContext.Provider>
  )
}

export function useSectionIndicator() {
  const context = React.useContext(SectionIndicatorContext)

  if (!context) {
    throw new Error(
      '`useSectionIndicator` must be used within a `SectionIndicatorContext`',
    )
  }

  return context
}

export default SectionIndicatorContext

import {BoxProps, Grid, styled, useMediaQuery} from '@mui/material'
import {graphql, StaticQuery} from 'gatsby'
import Image from 'gatsby-image'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import * as React from 'react'
import {useWidth} from '../../../lib'
import useMenuHighlighter from '../../../lib/useMenuHighlighter'
import {Paragraph, Section, SectionTitle} from '../../atoms'

export interface AboutUsSectionTemplateProps extends BoxProps {}

const aboutUsQuery = graphql`
  query {
    axfinaLogoLight: file(relativePath: {eq: "axfina-logo-light.png"}) {
      childImageSharp {
        fluid(maxWidth: 340) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    axfinaLogoDark: file(relativePath: {eq: "axfina-logo-dark.png"}) {
      childImageSharp {
        fluid(maxWidth: 340) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

const StyledSection = styled(Section)(({theme}) => ({
  padding: theme.spacing(4, 0, 6),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(10, 0),
  },
}))

const StyledSectionTitle = styled(SectionTitle)(({theme}) => ({
  padding: 0,
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 1.5),
  },
}))

const StyledParagraph = styled(Paragraph)(({theme}) => ({
  padding: 0,
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(1, 1.5),
  },
}))

const StyledImage = styled(Image)(({theme}) => ({
  top: '50%',
  transform: 'translateY(-50%)',
  maxWidth: 150,
  marginTop: theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    maxWidth: 340,
    margin: '0 auto',
  },
}))

export const AboutUsSectionTemplate: React.FC<AboutUsSectionTemplateProps> = (
  props,
) => {
  const {t} = useTranslation()
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const containerRef = React.useRef<HTMLDivElement>()
  const breakpoint = useWidth()
  const isMobile = ['xs', 'sm'].includes(breakpoint)

  useMenuHighlighter({
    target: containerRef.current,
    sectionName: 'about-us',
  })

  return (
    <StaticQuery query={aboutUsQuery}>
      {({axfinaLogoLight, axfinaLogoDark}) => (
        <StyledSection ref={containerRef} {...props}>
          <Grid container>
            <Grid item md={6} xs={12} style={{order: isMobile ? 2 : 1}}>
              <StyledImage
                fluid={
                  prefersDarkMode
                    ? axfinaLogoLight.childImageSharp.fluid
                    : axfinaLogoDark.childImageSharp.fluid
                }
                alt={t('home.image.axfina.alt')}
              />
            </Grid>

            <Grid item md={6} xs={12} style={{order: 1}}>
              <StyledSectionTitle variant="h2" color="primary">
                {t('home.aboutUs.title')}
              </StyledSectionTitle>

              <StyledParagraph>
                {t('home.aboutUs.description.firstParagraph')}
              </StyledParagraph>

              <StyledParagraph
                sx={{
                  backgroundColor: (theme) => theme.palette.primary.main,
                  color: (theme) => theme.palette.text.primary,
                  fontWeight: (theme) => theme.typography.fontWeightBold,
                  borderRadius: 3,
                  margin: {xs: `0 -80px`, md: 0},
                  padding: {xs: `8px 80px`, md: `8px 12px`},
                }}
              >
                {t('home.aboutUs.description.secondParagraph')}
              </StyledParagraph>

              <StyledParagraph>
                {t('home.aboutUs.description.thirdParagraph')}
              </StyledParagraph>
            </Grid>
          </Grid>
        </StyledSection>
      )}
    </StaticQuery>
  )
}

export default AboutUsSectionTemplate

import {Box, styled} from '@mui/material'

import * as React from 'react'
import {pxToRem} from '../../../theme'

export interface ClickableSectionProps {
  badgeContent: React.ReactNode
  onClick?: VoidFunction
  status?: 'default' | 'highlighted'
}

const StyledContentContainer = styled(Box)({
  display: 'flex',
  flexFlow: 'row nowrap',
  cursor: 'pointer',
})

const StyledCircle = styled(Box)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 100,
  height: 100,
  borderRadius: '50%',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.common.white
      : theme.palette.text.secondary,
  transition: 'background-color 0.2s ease-in-out',
  fontWeight: theme.typography.fontWeightBold,
  fontFamily: '"Exo 2", Helvetica, Arial, sans-serif',
  fontSize: pxToRem(25),
}))

export const ClickableSection: React.FC<ClickableSectionProps> = ({
  badgeContent,
  onClick,
  status = 'default',
  children,
}) => {
  return (
    <StyledContentContainer onClick={onClick}>
      <Box>
        <StyledCircle
          sx={[
            status === 'highlighted'
              ? {
                  color: (theme) => theme.palette.common.white,
                  backgroundColor: (theme) => theme.palette.primary.main,
                }
              : {
                  color: (theme) =>
                    theme.palette.mode === 'dark'
                      ? theme.palette.primary.main
                      : theme.palette.common.white,
                },
          ]}
        >
          {badgeContent}
        </StyledCircle>
      </Box>

      <Box
        pl={2}
        pr={1}
        sx={[
          status === 'highlighted'
            ? {
                color: (theme) => theme.palette.primary.main,
              }
            : {
                color: (theme) =>
                  theme.palette.mode === 'dark'
                    ? theme.palette.text.primary
                    : theme.palette.text.secondary,
              },
        ]}
      >
        {children}
      </Box>
    </StyledContentContainer>
  )
}

export default ClickableSection

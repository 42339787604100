import {Typography, TypographyProps} from '@mui/material'
import * as React from 'react'

export interface ErrorMessageProps extends Omit<TypographyProps, 'color'> {}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({
  children,
  ...props
}) => {
  return (
    <Typography color="error" {...props}>
      ({children})
    </Typography>
  )
}

export default ErrorMessage

import {Box, Card, styled, Typography} from '@mui/material'
import lottie from 'lottie-web'
import * as React from 'react'
import {pxToRem} from '../../../theme'

export interface ShowcaseCardProps {
  title: string
  description: string
  titleAlignment?: 'left' | 'center' | 'default'
  animationData?: any
  animationName?: string
  comingSoon?: boolean
  comingSoonTitle?: string
}

const StyledCardContainer = styled(Box)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    marginBottom: 0,
  },
}))

const StyledImageCard = styled(Card)(({theme}) => ({
  position: 'relative',
  margin: '0 auto',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.background.default
      : theme.palette.common.black,
  zIndex: 2,
  borderRadius: 22,
  width: 116,
  height: 87,
  [theme.breakpoints.up('sm')]: {
    width: '60%',
    height: 165,
    borderRadius: 30,
  },
  [theme.breakpoints.up('lg')]: {
    width: '80%',
  },
}))

const StyledContentCard = styled(Card)(({theme}) => ({
  position: 'relative',
  marginTop: theme.spacing(-7),
  padding: theme.spacing(9, '10%', 8),
  zIndex: 1,
  flex: '1 1 auto',
  backgroundColor: theme.palette.common.white,
  color: theme.palette.mode === 'dark' ? theme.palette.common.black : 'inherit',
  borderRadius: 22,
  width: '80%',
  margin: theme.spacing(-7, 'auto', 0),
  [theme.breakpoints.up(400)]: {
    width: '100%',
  },
  [theme.breakpoints.up('sm')]: {
    borderRadius: 30,
  },
}))

const StyledTitleContainer = styled(Box)(({theme}) => ({
  minHeight: 'initial',
  [theme.breakpoints.up('sm')]: {
    minHeight: 60,
  },
}))

const StyledComingSoonLabel = styled(Typography)(({theme}) => ({
  fontSize: pxToRem(16),
  textTransform: 'uppercase',
  lineHeight: '19px',
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightBold,
  textAlign: 'center',
}))

const StyledContentTitle = styled(Typography)(({theme}) => ({
  fontSize: pxToRem(16),
  textTransform: 'uppercase',
  lineHeight: '19px',
  marginBottom: theme.spacing(),
}))

const StyledContentDescription = styled(Typography)({
  fontSize: pxToRem(14),
  lineHeight: '150%',
})

export const ShowcaseCard: React.FC<ShowcaseCardProps> = ({
  animationData,
  animationName,
  title,
  description,
  titleAlignment = 'default',
  comingSoon,
  comingSoonTitle = 'Coming soon',
}) => {
  const animationContainer = React.useRef<HTMLDivElement>()

  React.useEffect(() => {
    lottie.loadAnimation({
      name: animationName,
      renderer: 'svg',
      autoplay: false,
      loop: false,
      container: animationContainer.current,
      animationData,
    })

    return () => lottie.destroy(animationName)
  }, [animationContainer.current, animationData, animationName])

  function playAnimation() {
    lottie.play(animationName)
  }

  function pauseAnimation() {
    lottie.stop(animationName)
  }

  return (
    <StyledCardContainer
      onMouseEnter={playAnimation}
      onMouseLeave={pauseAnimation}
    >
      <StyledImageCard ref={animationContainer} elevation={0}></StyledImageCard>

      <StyledContentCard
        sx={[
          comingSoon && {
            '>*': {
              opacity: 0.5,
            },
          },
        ]}
        elevation={0}
      >
        <StyledTitleContainer>
          {comingSoon && (
            <StyledComingSoonLabel>{comingSoonTitle}</StyledComingSoonLabel>
          )}

          <StyledContentTitle
            variant="h3"
            sx={[
              titleAlignment === 'left' && {
                textAlign: 'left',
              },
              titleAlignment === 'center' && {textAlign: 'center'},
            ]}
          >
            {title}
          </StyledContentTitle>
        </StyledTitleContainer>

        <StyledContentDescription>{description}</StyledContentDescription>
      </StyledContentCard>
    </StyledCardContainer>
  )
}

export default ShowcaseCard

import {
  Box,
  BoxProps,
  Grid,
  styled,
  Typography,
  useMediaQuery,
} from '@mui/material'
import Image, {GatsbyImageFluidProps} from 'gatsby-image'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import lottie, {AnimationConfigWithData, AnimationItem} from 'lottie-web'
import * as React from 'react'
import authenticationAnimation from '../../../animations/authentication.json'
import paymentAnimation from '../../../animations/payment.json'
import termsAnimation from '../../../animations/terms.json'
import {useSectionIndicator} from '../../../context'
import {useWidth} from '../../../lib'
import useMenuHighlighter from '../../../lib/useMenuHighlighter'
import {pxToRem} from '../../../theme'
import {Headline, Section, SectionTitle} from '../../atoms'
import ClickableSection from '../ClickableSection'

export interface HowItWorksSectionTemplateProps extends BoxProps {
  image: GatsbyImageFluidProps
}

const StyledSection = styled(Section)(({theme}) => ({
  padding: theme.spacing(8, 0, 6),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(12, 0, 6),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(15, 0, 6),
  },
}))

const StyledSectionTitle = styled(SectionTitle)(({theme}) => ({
  marginBottom: theme.spacing(6),
  [theme.breakpoints.up('md')]: {
    marginBottom: theme.spacing(11),
  },
}))

const StyledDescription = styled(Typography)(({theme}) => ({
  fontSize: pxToRem(14),
  lineHeight: '150%',
  transition: 'color 0.2s ease-in-out',
  marginTop: theme.spacing(2),
}))

const StyledSectionContainerGrid = styled(Grid)(({theme}) => ({
  [theme.breakpoints.up('sm')]: {
    paddingRight: theme.spacing(3),
  },
}))

const StyledImageContainerGrid = styled(Grid)(({theme}) => ({
  position: 'relative',
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}))

const StyledMobileFrameWrapper = styled(Box)({
  position: 'relative',
})

const StyledImage = styled(Image)(({theme}) => ({
  maxWidth: 200,
  margin: theme.spacing(4, 'auto'),
  [theme.breakpoints.up('md')]: {
    maxWidth: 285,
    margin: '0 auto',
  },
}))

const animations = async (language: string) => {
  const registrationAnimation = await import(
    `../../../animations/${language}/registration.json`
  )

  return [
    registrationAnimation,
    authenticationAnimation,
    termsAnimation,
    paymentAnimation,
  ]
}

const SINGULAR_ANIMATION_NAME = 'how-it-works-animation'

export const HowItWorksSectionTemplate: React.FC<HowItWorksSectionTemplateProps> = ({
  image,
  ...props
}) => {
  const {t} = useTranslation()
  const [highlightedSectionName] = useSectionIndicator()
  const isSectionVisible = highlightedSectionName === 'how-it-works'
  const [highlightedSection, setHighlightedSection] = React.useState(0)
  const [fadeInAnimation, setFadeInAnimation] = React.useState(true)
  const animationContainer = React.useRef<HTMLDivElement>()
  const timeoutRef = React.useRef<NodeJS.Timeout>()
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const [animationDuration, setAnimationDuration] = React.useState<number>()
  const data = useI18next()
  const [fetchedAnimations, setFetchedAnimations] = React.useState<
    Array<string>
  >()

  const breakpoint = useWidth()
  const isMobile = ['xs', 'sm'].includes(breakpoint)

  const containerRef = React.useRef<HTMLDivElement>()

  useMenuHighlighter({
    target: containerRef.current,
    sectionName: 'how-it-works',
  })

  React.useEffect(() => {
    async function fetchAnimations() {
      const animationList = await animations(data.language)

      setFetchedAnimations(animationList)
    }

    fetchAnimations()
  }, [data.language])

  React.useEffect(() => {
    let animation: AnimationItem

    if (fetchedAnimations) {
      const animationConfig: AnimationConfigWithData = {
        name: SINGULAR_ANIMATION_NAME,
        renderer: 'svg',
        autoplay: true,
        loop: false,
        container: animationContainer.current,
        animationData: fetchedAnimations[highlightedSection],
        rendererSettings: {
          progressiveLoad: true,
        },
      }
      animation = lottie.loadAnimation(animationConfig)

      animation.pause()
      animation.addEventListener('complete', onAnimationCompleted)
      setAnimationDuration(animation.getDuration() * 1000)
    }

    return () => {
      if (animation) {
        animation.removeEventListener('complete', onAnimationCompleted)
        setAnimationDuration(undefined)
        lottie.destroy(SINGULAR_ANIMATION_NAME)
      }
    }
  }, [
    fetchedAnimations,
    animationContainer.current,
    highlightedSection,
    setAnimationDuration,
  ])

  React.useEffect(() => {
    if (isSectionVisible && !isMobile) {
      lottie.play(SINGULAR_ANIMATION_NAME)
    } else {
      lottie.pause(SINGULAR_ANIMATION_NAME)
    }
  }, [isSectionVisible, isMobile])

  React.useEffect(() => {
    lottie.play(SINGULAR_ANIMATION_NAME)
  }, [highlightedSection])

  function onAnimationCompleted() {
    setHighlightedSection(
      highlightedSection === 3 ? highlightedSection : highlightedSection + 1,
    )
  }

  React.useEffect(() => {
    if (isSectionVisible) {
      setFadeInAnimation(true)

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      if (highlightedSection !== 3) {
        timeoutRef.current = setTimeout(() => {
          if (isSectionVisible) {
            setFadeInAnimation(false)
          }
        }, animationDuration - 500)
      }
    }
  }, [
    highlightedSection,
    setFadeInAnimation,
    animationDuration,
    isSectionVisible,
  ])

  const sectionStatus = (index: number) =>
    highlightedSection === index && !isMobile ? 'highlighted' : 'default'

  function handleSectionClick(index: number) {
    if (isMobile) {
      return
    }

    setHighlightedSection(index)
  }

  return (
    <StyledSection ref={containerRef} {...props}>
      <StyledSectionTitle
        variant="h2"
        color={prefersDarkMode ? 'textPrimary' : 'textSecondary'}
      >
        {t('home.howItWorks.title')}
      </StyledSectionTitle>

      <Grid container>
        <Grid item md={6} sm={12} xs={12}>
          <StyledSectionContainerGrid
            container
            direction={isMobile ? 'row' : 'column'}
            spacing={6}
          >
            <Grid item xs={12}>
              <ClickableSection
                badgeContent="1."
                status={sectionStatus(0)}
                onClick={() => handleSectionClick(0)}
              >
                <Headline variant="h5" component="h3">
                  {t('home.howItWorks.registration.title')}
                </Headline>

                <StyledDescription>
                  {t('home.howItWorks.registration.description.firstParagraph')}
                </StyledDescription>

                <StyledDescription>
                  {t(
                    'home.howItWorks.registration.description.secondParagraph',
                  )}
                </StyledDescription>
              </ClickableSection>
            </Grid>

            <Grid item xs={12}>
              <ClickableSection
                badgeContent="2."
                status={sectionStatus(1)}
                onClick={() => handleSectionClick(1)}
              >
                <Headline variant="h5" component="h3">
                  {t('home.howItWorks.communication.title')}
                </Headline>

                <StyledDescription>
                  {t('home.howItWorks.communication.description')}
                </StyledDescription>
              </ClickableSection>
            </Grid>

            <Grid item xs={12}>
              <ClickableSection
                badgeContent="3."
                status={sectionStatus(2)}
                onClick={() => handleSectionClick(2)}
              >
                <Headline variant="h5" component="h3">
                  {t('home.howItWorks.finances.title')}
                </Headline>

                <StyledDescription>
                  {t('home.howItWorks.finances.description')}
                </StyledDescription>
              </ClickableSection>
            </Grid>

            <Grid item xs={12}>
              <ClickableSection
                badgeContent="4."
                status={sectionStatus(3)}
                onClick={() => handleSectionClick(3)}
              >
                <Headline variant="h5" component="h3">
                  {t('home.howItWorks.payments.title')}
                </Headline>

                <StyledDescription>
                  {t('home.howItWorks.payments.description')}
                </StyledDescription>
              </ClickableSection>
            </Grid>
          </StyledSectionContainerGrid>
        </Grid>

        <StyledImageContainerGrid item md={6}>
          <StyledMobileFrameWrapper>
            <StyledImage
              fluid={image.fluid}
              alt={t('home.image.animatedPhone.alt')}
            />

            <Box
              ref={animationContainer}
              sx={[
                {
                  position: 'absolute',
                  top: '50%',
                  left: 0,
                  right: 0,
                  margin: '0 auto',
                  transform: 'translateY(-50%)',
                  opacity: 0,
                  transition: 'opacity 0.5s ease-in-out',
                  width: {xs: 150, md: 200},
                },
                fadeInAnimation && {
                  opacity: 1,
                },
                highlightedSection === 2 && {
                  width: {xs: 175, md: 250},
                  transform: 'translateY(-50%)',
                },
              ]}
            />
          </StyledMobileFrameWrapper>
        </StyledImageContainerGrid>
      </Grid>
    </StyledSection>
  )
}

export default HowItWorksSectionTemplate

import * as React from 'react'
import {PasswordForm} from '../../components'

const PasswordContext = React.createContext<{
  onSubmitPassword: (password: string) => boolean
}>(undefined)

// note: This is a hash of our desired password
const REQUIRED_PASSWORD = '-1744638975'

function hashPassword(s: string) {
  return s
    .split('')
    .reduce((a, b) => {
      a = (a << 5) - a + b.charCodeAt(0)
      return a & a
    }, 0)
    .toString()
}

export const PasswordProvider: React.FC = ({children}) => {
  const [correctPasswordProvided, setCorrectPasswordProvided] = React.useState(
    () => process.env.GATSBY_DEV_MODE === 'false',
  )

  React.useEffect(() => {
    if (!correctPasswordProvided) {
      setCorrectPasswordProvided(
        typeof window !== 'undefined' &&
          (window.location.href.includes('finastic') ||
            window.sessionStorage.getItem('password') === REQUIRED_PASSWORD),
      )
    }
  }, [correctPasswordProvided])

  function handlePasswordSubmit(password: string) {
    const hashedPassword = hashPassword(password)
    const isCorrect = hashPassword(password) === REQUIRED_PASSWORD

    if (isCorrect) {
      window.sessionStorage.setItem('password', hashedPassword)
    }

    setCorrectPasswordProvided(isCorrect)

    return isCorrect
  }

  return (
    <PasswordContext.Provider value={{onSubmitPassword: handlePasswordSubmit}}>
      {correctPasswordProvided ? children : <PasswordForm />}
    </PasswordContext.Provider>
  )
}

export function useDevelopmentPassword() {
  const context = React.useContext(PasswordContext)

  if (!context) {
    throw new Error(
      '`useDevelopmentPassword` must be used within a `PasswordContext`',
    )
  }

  return context
}

export default PasswordContext

import {
  alpha,
  AppBar,
  Backdrop,
  Box,
  Container,
  drawerClasses,
  styled,
  SwipeableDrawer,
} from '@mui/material'
import {graphql, StaticQuery} from 'gatsby'
import {GatsbyImageFluidProps} from 'gatsby-image'
import Image from 'gatsby-image/withIEPolyfill'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import * as React from 'react'
import {useSectionIndicator} from '../../../context'
import {IconButton, MenuClosedIcon, MenuOpenIcon, RouteLink} from '../../atoms'
import {Navigation} from '../../molecules/'

export interface HeaderProps {
  logo: GatsbyImageFluidProps
  logoAlt?: string
  activeMenuHref?: string
  subpage?: boolean
}

const MOBILE_MENU_BREAKPOINT = 1280

interface HeaderData {
  generalSettings: {
    frontmatter: {loginRegistrationUrl: string}
  }
}

const headerQuery = graphql`
  query {
    generalSettings: markdownRemark(
      frontmatter: {templateKey: {eq: "general-settings"}}
    ) {
      frontmatter {
        loginRegistrationUrl
      }
    }
  }
`

const StyledAppBar = styled(AppBar)(({theme}) => ({
  backgroundColor: theme.palette.background.default,
  height: 56,
  boxSizing: 'border-box',
  zIndex: theme.zIndex.appBar,
}))

const AppBarContent = styled(Container)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '100%',
})

const Menu = styled(Box)(({theme}) => ({
  [theme.breakpoints.down(MOBILE_MENU_BREAKPOINT)]: {
    display: 'none',
  },
  '& a + a:not(:last-child)': {
    marginLeft: theme.spacing(1),
  },
  '& a + a:last-child': {
    marginLeft: theme.spacing(2.5),
  },
}))

const MobileMenuButton = styled(IconButton)(({theme}) => ({
  [theme.breakpoints.up(MOBILE_MENU_BREAKPOINT)]: {
    display: 'none',
  },
}))

const MobilePlaceholder = styled(Box)(({theme}) => ({
  [theme.breakpoints.down(MOBILE_MENU_BREAKPOINT)]: {
    display: 'block',
  },
  opacity: 0,
  width: 20,
  height: 20,
}))

const StyledDrawer = styled(SwipeableDrawer)(({theme}) => ({
  [`& .${drawerClasses.paper}`]: {
    backgroundColor: alpha(theme.palette.background.default, 0.97),
    padding: theme.spacing(2, 2, 9, 2),
    top: 56,
    bottom: 0,
    height: '100%',
    minWidth: 263,
    '& a + a:not(:last-child)': {
      marginTop: theme.spacing(1.5),
    },
    '& a + a:last-child': {
      marginTop: theme.spacing(2),
    },
  },
}))

const StyledBackdrop = styled(Backdrop)(({theme}) => ({
  backgroundColor: alpha(theme.palette.common.black, 0),
}))

const StyledImage = styled(Image)(({theme}) => ({
  '& img': {
    objectFit: 'contain !important',
  },
  [theme.breakpoints.down(MOBILE_MENU_BREAKPOINT)]: {
    width: 190,
  },
  [theme.breakpoints.up(MOBILE_MENU_BREAKPOINT)]: {
    width: 160,
  },
}))

export const Header: React.FC<HeaderProps> = ({logo, logoAlt, subpage}) => {
  const {t} = useTranslation()
  const {language} = useI18next()
  const [open, setOpen] = React.useState(false)
  const [highlightedSection] = useSectionIndicator()

  function toggleMenu() {
    setOpen(!open)
  }

  return (
    <StaticQuery query={headerQuery}>
      {({generalSettings}: HeaderData) => (
        <>
          <StyledAppBar position="fixed" color="default" elevation={0}>
            <AppBarContent maxWidth="lg">
              <MobileMenuButton
                sx={{
                  borderColor: open
                    ? (theme) => theme.palette.primary.main
                    : undefined,
                }}
                aria-label={
                  open
                    ? t('navigation.accessibility.menu.close')
                    : t('navigation.accessibility.menu.open')
                }
                onClick={toggleMenu}
              >
                {open ? (
                  <MenuOpenIcon fontSize="small" color="primary" />
                ) : (
                  <MenuClosedIcon fontSize="small" />
                )}
              </MobileMenuButton>

              <RouteLink to={`/${language}/`} hrefLang={language}>
                <StyledImage fluid={logo.fluid} alt={logoAlt} />
              </RouteLink>

              <MobilePlaceholder />

              <Menu>
                <Navigation
                  highlightedIndicator={highlightedSection}
                  subpage={subpage}
                  loginRegistrationUrl={
                    generalSettings.frontmatter.loginRegistrationUrl
                  }
                />
              </Menu>
            </AppBarContent>
          </StyledAppBar>

          <StyledDrawer
            anchor="left"
            open={open}
            onOpen={toggleMenu}
            onClose={toggleMenu}
            components={{Backdrop: StyledBackdrop}}
            elevation={0}
          >
            <Navigation
              highlightedIndicator={highlightedSection}
              onLinkClick={toggleMenu}
              subpage={subpage}
              loginRegistrationUrl={
                generalSettings.frontmatter.loginRegistrationUrl
              }
            />
          </StyledDrawer>
        </>
      )}
    </StaticQuery>
  )
}

export default Header

import '@fontsource/exo-2'
import '@fontsource/exo-2/500-italic.css'
import '@fontsource/exo-2/500.css'
import '@fontsource/exo-2/700.css'
import '@fontsource/open-sans'
import '@fontsource/open-sans/700.css'
import React from 'react'
import smoothscroll from 'smoothscroll-polyfill'
import TopLayout from './TopLayout'

smoothscroll.polyfill()

export const wrapRootElement = ({element}) => {
  return <TopLayout>{element}</TopLayout>
}

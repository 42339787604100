import {Box, Button, darken, lighten, styled} from '@mui/material'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import * as React from 'react'
import {useAlert} from '../../../context'
import {pxToRem} from '../../../theme'
import {CloseIcon} from '../icons'

export interface AlertProps {
  variant: 'error' | 'success'
}

const StyledAlertContainer = styled(Box)(({theme}) => ({
  position: 'fixed',
  top: 40,
  left: 0,
  right: 0,
  margin: '0 auto',
  zIndex: theme.zIndex.appBar + 1,
  maxWidth: 350,
  minWidth: 250,
  padding: theme.spacing(2, 3),
  borderRadius: 4,
  fontSize: pxToRem(16),
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

const StyledCloseButton = styled(Button)({
  minWidth: 'initial',
})

export const Alert: React.FC<AlertProps> = ({variant, children, ...props}) => {
  const {t} = useTranslation()
  const {clearAlert} = useAlert()

  function closeAlertOnEscape(event) {
    if (event.key === 'Escape') {
      clearAlert()
    }
  }

  React.useEffect(() => {
    document.addEventListener('keydown', closeAlertOnEscape)

    return () => document.removeEventListener('keydown', closeAlertOnEscape)
  }, [clearAlert])

  return (
    <StyledAlertContainer
      className="alert"
      sx={{
        backgroundColor: (theme) =>
          variant === 'error'
            ? lighten(theme.palette.error.light, 0.5)
            : variant === 'success'
            ? lighten(theme.palette.success.light, 0.5)
            : undefined,
        color: (theme) =>
          variant === 'error'
            ? darken(theme.palette.error.dark, 0.3)
            : variant === 'success'
            ? darken(theme.palette.success.dark, 0.3)
            : undefined,
      }}
      {...props}
    >
      <Box>{children}</Box>

      <StyledCloseButton
        onClick={clearAlert}
        aria-label={t('navigation.accessibility.close')}
      >
        <CloseIcon
          sx={{
            stroke: (theme) =>
              variant === 'error'
                ? darken(theme.palette.error.dark, 0.3)
                : variant === 'success'
                ? darken(theme.palette.success.dark, 0.3)
                : undefined,
          }}
        />
      </StyledCloseButton>
    </StyledAlertContainer>
  )
}

export default Alert

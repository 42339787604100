import {ThemeOptions} from '@mui/material'
import {pxToRem} from '../utils'

export const commonTheme: ThemeOptions = {
  palette: {
    primary: {
      main: '#FAA61B',
    },
  },
  typography: {
    fontFamily: '"Open Sans", Helvetica, Arial, sans-serif',
    h1: {
      fontSize: pxToRem(48),
      fontWeight: 'bold',
    },
    h2: {
      fontSize: pxToRem(42),
      fontWeight: 'bold',
    },
    h3: {
      fontSize: pxToRem(32),
      fontWeight: 'bold',
    },
    h4: {
      fontSize: pxToRem(22),
      fontWeight: 'bold',
    },
    h5: {
      fontSize: pxToRem(16),
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    h6: {
      fontSize: pxToRem(14),
      fontWeight: 'bold',
      marginBottom: 8,
    },
    body1: {
      fontSize: pxToRem(14),
    },
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1280,
      xl: 1536,
    },
  },
}

export default commonTheme

import {createTheme} from '@mui/material'
import commonTheme from '../common'

export const lightTheme = createTheme({
  ...commonTheme,
  palette: {
    ...commonTheme.palette,
    mode: 'light',
    text: {
      primary: '#222230',
      secondary: '#4E536D',
      disabled: '#4E536D',
    },
    divider: '#46444F',
    background: {
      default: '#FFFFFF',
    },
    common: {
      black: '#222230',
    },
  },
})

export default lightTheme

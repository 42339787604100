import {SvgIcon, SvgIconProps} from '@mui/material'
import * as React from 'react'

export const MenuOpenIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 12C3 11.4477 3.44772 11 4 11L20 11C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13L4 13C3.44772 13 3 12.5523 3 12Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 6C3 5.44772 3.44772 5 4 5L20 5C20.5523 5 21 5.44772 21 6C21 6.55228 20.5523 7 20 7L4 7C3.44772 7 3 6.55228 3 6Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 18C3 17.4477 3.44772 17 4 17L12 17C12.5523 17 13 17.4477 13 18C13 18.5523 12.5523 19 12 19L4 19C3.44772 19 3 18.5523 3 18Z"
    />
  </SvgIcon>
)

export default MenuOpenIcon

import {styled, useTheme} from '@mui/material'
import {GatsbyLinkProps, Link as GatsbyLink} from 'gatsby'
import * as React from 'react'
import {pxToRem} from '../../../theme'
import Link from '../Link'

export interface RouteLinkProps
  extends Pick<
    GatsbyLinkProps<{}>,
    | 'to'
    | 'partiallyActive'
    | 'activeClassName'
    | 'activeStyle'
    | 'onClick'
    | 'hrefLang'
  > {
  navLink?: boolean
  disableActive?: boolean
  className?: string
  padding?: 'default' | 'no-horizontal'
}

const StyledGatsbyLink = styled(GatsbyLink)(({theme}) => ({
  color: theme.palette.text.primary,
  fontSize: pxToRem(14),
  fontFamily: '"Exo 2", Helvetica, Arial, sans-serif',
  textDecoration: 'none',
  transition: 'color 0.25s ease-in-out, border-color 0.25s ease-in-out',
  '&:hover': {
    textDecoration: 'underline',
  },
}))

export const RouteLink: React.FC<RouteLinkProps> = ({
  navLink,
  disableActive,
  padding = 'default',
  ...props
}) => {
  const theme = useTheme()

  return (
    <Link
      component={StyledGatsbyLink}
      sx={[
        navLink && {
          border: `1px solid transparent`,
          borderRadius: 2,
          padding: theme.spacing(0.75, 1.5),
        },
        padding === 'no-horizontal' && {
          paddingLeft: 0,
          paddingRight: 0,
        },
      ]}
      underline="none"
      activeStyle={
        !disableActive && navLink
          ? {
              color: theme.palette.primary.main,
              fontWeight: theme.typography.fontWeightBold,
              border: `1px solid ${theme.palette.primary.main}`,
            }
          : {}
      }
      {...props}
    />
  )
}

export default RouteLink

import {SvgIcon, SvgIconProps} from '@mui/material'
import * as React from 'react'

export const InstagramIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 3C4.79086 3 3 4.79086 3 7V17C3 19.2091 4.79086 21 7 21H17C19.2091 21 21 19.2091 21 17V7C21 4.79086 19.2091 3 17 3H7ZM1 7C1 3.68629 3.68629 1 7 1H17C20.3137 1 23 3.68629 23 7V17C23 20.3137 20.3137 23 17 23H7C3.68629 23 1 20.3137 1 17V7Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4833 8.98924C11.8591 8.89668 11.2217 9.0033 10.6616 9.29392C10.1015 9.58455 9.64726 10.0444 9.36356 10.608C9.07986 11.1717 8.98112 11.8104 9.08137 12.4334C9.18161 13.0564 9.47575 13.6319 9.92194 14.0781C10.3681 14.5243 10.9437 14.8184 11.5667 14.9187C12.1896 15.0189 12.8284 14.9202 13.392 14.6365C13.9557 14.3528 14.4155 13.8986 14.7061 13.3385C14.9968 12.7784 15.1034 12.1409 15.0108 11.5167C14.9164 10.88 14.6197 10.2906 14.1646 9.83547C13.7095 9.38034 13.12 9.08366 12.4833 8.98924ZM9.74041 7.51868C10.6739 7.0343 11.7364 6.85661 12.7767 7.01087C13.8378 7.16823 14.8202 7.6627 15.5788 8.42126C16.3374 9.17981 16.8318 10.1622 16.9892 11.2234C17.1434 12.2637 16.9658 13.3261 16.4814 14.2596C15.997 15.1931 15.2306 15.9501 14.2912 16.423C13.3518 16.8958 12.2872 17.0604 11.2489 16.8933C10.2106 16.7262 9.25138 16.236 8.50773 15.4923C7.76408 14.7487 7.27385 13.7895 7.10677 12.7511C6.93969 11.7128 7.10426 10.6482 7.57709 9.70884C8.04992 8.76944 8.80691 8.00305 9.74041 7.51868Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5 7C18.0523 7 18.5 6.55228 18.5 6C18.5 5.44772 18.0523 5 17.5 5C16.9477 5 16.5 5.44772 16.5 6C16.5 6.55228 16.9477 7 17.5 7Z"
    />
  </SvgIcon>
)

export default InstagramIcon

import {styled, Typography, TypographyProps} from '@mui/material'
import * as React from 'react'

export interface HeadlineProps extends Omit<TypographyProps, 'variant'> {
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  component?: string
}

const StyledTypography = styled(Typography)({
  transition: 'color 0.2s ease-in-out',
  fontFamily: '"Exo 2", Calibri, Helvetica, Arial Sans Serif',
})

export const Headline: React.FC<HeadlineProps> = ({
  variant = 'h1',
  ...props
}) => {
  return <StyledTypography variant={variant} {...props} />
}

export default Headline

import {useForm} from '@formspree/react'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  darken,
  FormControlLabel,
  Grid,
  styled,
} from '@mui/material'
import {Field, Form, Formik} from 'formik'
import {Trans, useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import * as React from 'react'
import * as Yup from 'yup'
import {FORMSPREE_API_KEY, languageMap} from '../../../constants'
import {useAlert} from '../../../context'
import {pxToRem} from '../../../theme'
import {RouteLink, TextInput, TextInputProps} from '../../atoms'

export interface ContactFormProps {
  sizeVariant?: 'mobile' | 'desktop'
}

interface ContactFormValues {
  name: string
  email: string
  phone: string
  message: string
}

const StyledFormContainer = styled(Box)(({theme}) => ({
  borderRadius: 30,
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.common.black : '#4e536d',
  padding: theme.spacing(3),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(3, 5),
  },
}))

const StyledSubmitButtonGrid = styled(Grid)({
  display: 'flex',
  justifyContent: 'flex-end',
})

const StyledButton = styled(Button)(({theme}) => ({
  fontWeight: theme.typography.fontWeightBold,
  borderRadius: 12,
  fontSize: pxToRem(12),
  padding: theme.spacing(1.5, 5),
  color: theme.palette.common.black,
  backgroundColor: theme.palette.primary.main,
  fontFamily: '"Open Sans", Helvetica, Arial, sans-serif',
  '&:hover': {
    backgroundColor: darken(theme.palette.primary.main, 0.1),
  },
}))

const StyledRouteLink = styled(RouteLink)(({theme}) => ({
  color: theme.palette.primary.main,
}))

const initialValues: ContactFormValues = {
  name: '',
  email: '',
  phone: '',
  message: '',
}

const FormInput: React.FC<TextInputProps> = ({...props}) => {
  return <Field as={TextInput} {...props} />
}

export const ContactForm: React.FC<ContactFormProps> = ({
  sizeVariant,
  children,
}) => {
  const {language} = useI18next()
  const {t} = useTranslation()
  const {setAlert} = useAlert()
  const {formspreeAPIKey: formspreeAPIKeyForLocale} =
    languageMap[language] || {}
  const [state, handleFormspreeSubmit] = useForm(
    formspreeAPIKeyForLocale || FORMSPREE_API_KEY,
  )
  const [termsChecked, setTermsChecked] = React.useState(false)

  const validationSchema = Yup.object().shape({
    name: Yup.string().nullable().required(t('validation.field.required')),
    email: Yup.string()
      .nullable()
      .required(t('validation.field.required'))
      .email(t('validation.email.invalid')),
    phone: Yup.string().nullable(),
    message: Yup.string().nullable().required(t('validation.field.required')),
    terms: Yup.boolean().isTrue(),
  })

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, helpers) => {
        try {
          await handleFormspreeSubmit(values)

          setAlert({text: t('alert.message.sent.successful'), type: 'success'})

          helpers.resetForm()
          setTermsChecked(false)
        } catch (error) {
          setAlert({
            text: t('alert.message.sent.error'),
            type: 'error',
          })
        }
      }}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <StyledFormContainer>
        {children}

        <Form>
          <Grid
            container
            direction="column"
            spacing={sizeVariant === 'mobile' ? 3 : 2}
          >
            <Grid item>
              <Grid
                container
                direction="row"
                spacing={sizeVariant === 'mobile' ? 3 : 4}
              >
                <Grid item md={6} sm={6} xs={12}>
                  <FormInput
                    id="name-field"
                    label={t('home.contactUs.form.name.label')}
                    placeholder={t('home.contactUs.form.name.placeholder')}
                    name="name"
                    fullWidth
                    required
                  />
                </Grid>

                <Grid item md={6} sm={6} xs={12}>
                  <FormInput
                    id="email-field"
                    label={t('home.contactUs.form.email.label')}
                    placeholder={t('home.contactUs.form.email.placeholder')}
                    name="email"
                    type="email"
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <FormInput
                id="phone-field"
                label={t('home.contactUs.form.phone.label')}
                placeholder={t('home.contactUs.form.phone.placeholder')}
                name="phone"
                type="tel"
                fullWidth
              />
            </Grid>

            <Grid item>
              <FormInput
                id="message-field"
                label={t('home.contactUs.form.message.label')}
                placeholder={t('home.contactUs.form.message.placeholder')}
                name="message"
                fullWidth
                multiline
                required
              />
            </Grid>

            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{color: (theme) => theme.palette.common.white}}
                    color="primary"
                    required
                    checked={termsChecked}
                  />
                }
                onChange={(_, checked) => setTermsChecked(checked)}
                id="terms-checkbox"
                name="terms"
                label={
                  <Trans
                    i18nKey="home.contactUs.form.acceptTerms"
                    components={{
                      navlink: (
                        <StyledRouteLink
                          navLink
                          to={`/${language}/cookies-and-privacy-policy/`}
                          hrefLang={language}
                          padding="no-horizontal"
                        />
                      ),
                    }}
                  />
                }
                sx={{color: (theme) => theme.palette.common.white}}
              />
            </Grid>

            <StyledSubmitButtonGrid item>
              <StyledButton
                color="primary"
                disableElevation
                variant="contained"
                type="submit"
                disabled={state.submitting}
              >
                {state.submitting ? (
                  <CircularProgress size={22} />
                ) : (
                  <span>{t('home.contactUs.form.send')}</span>
                )}
              </StyledButton>
            </StyledSubmitButtonGrid>
          </Grid>
        </Form>
      </StyledFormContainer>
    </Formik>
  )
}

export default ContactForm

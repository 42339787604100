import {Box, BoxProps, Grid, styled, Typography} from '@mui/material'

import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import * as React from 'react'
import {useWidth} from '../../../lib'
import useMenuHighlighter from '../../../lib/useMenuHighlighter'
import {pxToRem} from '../../../theme'
import {
  ContactIcon,
  Headline,
  Link,
  PhoneIcon,
  RouteLink,
  Section,
} from '../../atoms'
import {ContactForm} from '../../molecules'

export interface ContactSectionTemplateProps extends BoxProps {
  phoneNumber: string
}

const StyledSection = styled(Section)(({theme}) => ({
  paddingBottom: theme.spacing(15),
  [theme.breakpoints.up('sm')]: {
    paddingBottom: theme.spacing(25),
  },
}))

const StyledHighlightedBox = styled(Box)(({theme}) => ({
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.primary.main,
  color:
    theme.palette.mode === 'dark'
      ? theme.palette.background.paper
      : theme.palette.text.primary,
  padding: theme.spacing(0.75, 4, 1.25, 1.5),
  maxHeight: 48,
  borderRadius: 12,
  '& > svg': {
    fontSize: pxToRem(27),
    marginTop: theme.spacing(0.5),
  },
  '& > * + *': {
    marginLeft: theme.spacing(1.5),
  },
  [theme.breakpoints.up('md')]: {
    display: 'inline-flex',
  },
}))

const StyledLink = styled(Link)(({theme}) => ({
  display: 'block',
  width: 'auto',
  height: 'auto',
  paddingLeft: 0,
  paddingRight: 0,
  marginBottom: theme.spacing(3.5),
  color:
    theme.palette.mode === 'dark'
      ? theme.palette.background.paper
      : theme.palette.text.primary,
}))

const StyledContactLink = styled(RouteLink)(({theme}) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '& span': {
    fontSize: pxToRem(19),
    fontWeight: theme.typography.fontWeightBold,
    fontFamily: '"Exo 2", Helvetica, Arial, sans-serif',
  },
}))

export const ContactSectionTemplate: React.FC<ContactSectionTemplateProps> = ({
  phoneNumber,
  ...props
}) => {
  const {language, defaultLanguage} = useI18next()
  const {t} = useTranslation()
  const breakpoint = useWidth()
  const isMobile = ['xs', 'sm'].includes(breakpoint)
  const containerRef = React.useRef<HTMLDivElement>()

  useMenuHighlighter({
    target: containerRef.current,
    sectionName: 'contact',
  })

  return (
    <StyledSection ref={containerRef} {...props}>
      <Grid
        container
        direction={isMobile ? 'column' : 'row'}
        spacing={isMobile ? 5 : 0}
      >
        <Grid
          item
          md={5}
          sm={12}
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Headline
            variant="h2"
            sx={{marginBottom: (theme) => theme.spacing(4)}}
          >
            {t('home.contactUs.title')}
          </Headline>

          <Headline
            variant="h4"
            component="h3"
            sx={{marginBottom: (theme) => theme.spacing(4)}}
          >
            {t('home.contactUs.subtitle')}
          </Headline>

          {phoneNumber && (
            <StyledLink
              href={`tel:${phoneNumber.replace(/ /gi, '')}`}
              underline="hover"
            >
              <StyledHighlightedBox component="span">
                <PhoneIcon color="inherit" />
                <Typography
                  component="span"
                  sx={{
                    fontSize: pxToRem(27),
                    fontWeight: (theme) => theme.typography.fontWeightBold,
                    lineHeight: '150%',
                    fontFamily: '"Exo 2", Helvetica, Arial, sans-serif',
                  }}
                >
                  {phoneNumber}
                </Typography>
              </StyledHighlightedBox>
            </StyledLink>
          )}

          <StyledContactLink
            to={defaultLanguage === language ? '/' : `/${language}`}
            hrefLang={language}
          >
            <ContactIcon
              fontSize="inherit"
              sx={{
                fontSize: pxToRem(48),
                marginRight: (theme) => theme.spacing(2),
              }}
            />

            <Typography component="span">
              {t('url')
                .split('.')
                .map((textPart, index) =>
                  index === 1 ? (
                    <React.Fragment key={textPart}>
                      .
                      {
                        <Typography color="primary" component="span">
                          {textPart}
                        </Typography>
                      }
                      .
                    </React.Fragment>
                  ) : (
                    <React.Fragment key={textPart}>{textPart}</React.Fragment>
                  ),
                )}
            </Typography>
          </StyledContactLink>
        </Grid>

        <Grid item md={7} sm={12} xs={12}>
          <ContactForm sizeVariant={isMobile ? 'mobile' : 'desktop'}>
            <Typography
              sx={{
                color: (theme) => theme.palette.common.white,
                marginBottom: (theme) => theme.spacing(4),
              }}
              variant="h4"
              component="h3"
            >
              {t('home.contactUs.sendMessage')}
            </Typography>
          </ContactForm>
        </Grid>
      </Grid>
    </StyledSection>
  )
}

export default ContactSectionTemplate

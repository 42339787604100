import {Box, BoxProps, Container, styled} from '@mui/material'
import * as React from 'react'

export interface SectionProps extends BoxProps {}

const StyledContentContainer = styled(Box)(({theme}) => ({
  position: 'relative',
  padding: theme.spacing(4, 0, 6),
}))

export const Section: React.FC<SectionProps> = React.forwardRef(
  ({children, ...props}, ref) => {
    return (
      <StyledContentContainer component="section" ref={ref} {...props}>
        <Container>{children}</Container>
      </StyledContentContainer>
    )
  },
)

Section.displayName = 'Section'

export default Section

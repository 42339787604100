import {Box, Grid, styled} from '@mui/material'
import {graphql, StaticQuery} from 'gatsby'
import Image, {GatsbyImageFixedProps} from 'gatsby-image'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import * as React from 'react'
import {pxToRem} from '../../../theme'
import {
  FacebookIcon,
  Headline,
  InstagramIcon,
  Link,
  RouteLink,
  Section,
} from '../../atoms'
import {Navigation} from '../../molecules'

type LoadedFixedImageProps = {childImageSharp: GatsbyImageFixedProps}

export interface FooterProps {
  subpage?: boolean
  phoneNumber?: string
}

interface FooterData {
  appStoreBadge: LoadedFixedImageProps
  googlePlayBadge: LoadedFixedImageProps
  storeUrls: {frontmatter: {appStoreUrl?: string; googlePlayUrl?: string}}
  socialUrls: {frontmatter: {facebookUrl?: string; instagramUrl?: string}}
  generalSettings: {
    frontmatter: {phoneNumber: string; loginRegistrationUrl: string}
  }
  primaryLegalDocument: {nodes: Array<{fields: {slug: string}}>}
}

const footerQuery = graphql`
  query {
    socialUrls: markdownRemark(
      frontmatter: {templateKey: {eq: "social-links"}}
    ) {
      frontmatter {
        facebookUrl
        instagramUrl
      }
    }
    storeUrls: markdownRemark(frontmatter: {templateKey: {eq: "store-links"}}) {
      frontmatter {
        appStoreUrl
        googlePlayUrl
      }
    }
    generalSettings: markdownRemark(
      frontmatter: {templateKey: {eq: "general-settings"}}
    ) {
      frontmatter {
        phoneNumber
        loginRegistrationUrl
      }
    }
    appStoreBadge: file(relativePath: {eq: "app-store-badge.png"}) {
      childImageSharp {
        fixed(height: 53) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    googlePlayBadge: file(relativePath: {eq: "google-play-badge.png"}) {
      childImageSharp {
        fixed(height: 53) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    primaryLegalDocument: allMarkdownRemark(
      filter: {frontmatter: {templateKey: {eq: "legal-documents"}}}
      sort: {fields: fields___slug, order: ASC}
    ) {
      nodes {
        fields {
          slug
        }
      }
    }
  }
`

const StyledSection = styled(Section)(({theme}) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.common.black : '#4e536d',
  color: theme.palette.common.white,
}))

const StyledGrid = styled(Grid)(({theme}) => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  padding: theme.spacing(1.5),
  borderLeft: `1px solid ${theme.palette.common.white}`,
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(1, 2),
    borderLeft: 'none',
  },
}))

const StyledStoreLinkGrid = styled(StyledGrid)(({theme}) => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  '& > * + *': {
    marginTop: theme.spacing(1.5),
  },
}))

const StyledLinkGrid = styled(StyledGrid)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  color: theme.palette.common.white,
  '& > * + *': {
    marginTop: theme.spacing(0.5),
  },
}))

const StyledGenericLink = styled(Link)(({theme}) => ({
  display: 'flex',
  padding: theme.spacing(0.75, 0),
  fontSize: pxToRem(14),
  color: theme.palette.common.white,
  fontFamily: '"Exo 2", Helvetica, Arial, sans-serif',
  '& + *': {
    marginTop: theme.spacing(0.5),
  },
  '&:hover': {
    color: theme.palette.common.white,
  },
}))

const StyledGenericRouteLink = styled(RouteLink)(({theme}) => ({
  display: 'flex',
  padding: theme.spacing(0.75, 0),
  fontSize: pxToRem(14),
  color: theme.palette.common.white,
  fontFamily: '"Exo 2", Helvetica, Arial, sans-serif',
  '& + *': {
    marginTop: theme.spacing(0.5),
  },
  '&:hover': {
    color: theme.palette.common.white,
  },
}))

const StyledNavLink = styled(RouteLink)(({theme}) => ({
  color: theme.palette.common.white,
}))

const StyledSocialLink = styled(Link)(({theme}) => ({
  display: 'inline-flex',
  padding: 0,
  color: theme.palette.common.white,
  '& + *': {
    marginLeft: theme.spacing(),
  },
}))

export const Footer: React.FC<FooterProps> = ({subpage, phoneNumber}) => {
  const {t} = useTranslation()
  const {language, defaultLanguage} = useI18next()

  return (
    <StaticQuery query={footerQuery}>
      {(data: FooterData) => {
        const {
          storeUrls,
          socialUrls,
          generalSettings,
          googlePlayBadge,
          appStoreBadge,
          primaryLegalDocument,
        } = data
        const {appStoreUrl, googlePlayUrl} = storeUrls.frontmatter
        const {facebookUrl, instagramUrl} = socialUrls.frontmatter
        const {
          phoneNumber: defaultPhoneNumber,
          loginRegistrationUrl,
        } = generalSettings.frontmatter
        const finalPhoneNumber = phoneNumber || defaultPhoneNumber
        const filteredPrimaryLegalDocument = {
          nodes: primaryLegalDocument.nodes
            ? primaryLegalDocument.nodes
                .filter(({fields}) => fields.slug.includes(`/${language}/`))
                .map(({fields, ...rest}) => {
                  let updatedSlug = fields.slug

                  if (updatedSlug.startsWith('/legal-documents')) {
                    const [pageType, , subPage] = updatedSlug
                      .split('/')
                      .filter(Boolean)

                    updatedSlug = `/${pageType}/${subPage}/`
                  }

                  return {fields: {...fields, slug: updatedSlug}, ...rest}
                })
            : [],
        }

        return (
          <StyledSection component="footer">
            <Grid container direction="row">
              <StyledGrid md={3} sm={6} xs={12} item>
                <Headline variant="h6" component="h4">
                  {t('footer.contactUs')}
                </Headline>

                <StyledGenericLink
                  href={`tel:${finalPhoneNumber.replace(/ /gi, '')}`}
                >
                  {finalPhoneNumber}
                </StyledGenericLink>

                <StyledGenericRouteLink
                  to={defaultLanguage === language ? '/' : `/${language}`}
                >
                  {t('url')}
                </StyledGenericRouteLink>
              </StyledGrid>

              <StyledLinkGrid md={3} sm={6} xs={12} item>
                <StyledNavLink
                  navLink
                  to={`/${language}/cookies-and-privacy-policy/`}
                  hrefLang={language}
                  disableActive
                  padding="no-horizontal"
                >
                  {t('footer.policies')}
                </StyledNavLink>
                {filteredPrimaryLegalDocument &&
                  filteredPrimaryLegalDocument.nodes[0] && (
                    <StyledNavLink
                      navLink
                      to={`/${language}${filteredPrimaryLegalDocument.nodes[0].fields.slug}`}
                      hrefLang={language}
                      disableActive
                      padding="no-horizontal"
                    >
                      {t('footer.termsAndConditions')}
                    </StyledNavLink>
                  )}
              </StyledLinkGrid>

              <StyledLinkGrid md={3} sm={6} xs={12} item>
                <Navigation
                  variant="footer"
                  subpage={subpage}
                  loginRegistrationUrl={loginRegistrationUrl}
                />
              </StyledLinkGrid>

              {(googlePlayUrl ||
                appStoreUrl ||
                facebookUrl ||
                instagramUrl) && (
                <StyledStoreLinkGrid md={3} sm={6} xs={12} item>
                  {googlePlayUrl && (
                    <Link
                      href={googlePlayUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      padding="none"
                    >
                      <Image
                        Tag="span"
                        fixed={googlePlayBadge.childImageSharp.fixed}
                        alt={t('home.image.googlePlay.alt')}
                      />
                    </Link>
                  )}

                  {appStoreUrl && (
                    <Link
                      href={appStoreUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      padding="none"
                    >
                      <Image
                        Tag="span"
                        fixed={appStoreBadge.childImageSharp.fixed}
                        alt={t('home.image.appStore.alt')}
                      />
                    </Link>
                  )}

                  {(facebookUrl || instagramUrl) && (
                    <Box>
                      {instagramUrl && (
                        <StyledSocialLink
                          href={instagramUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label={t('navigation.accessibility.instagram')}
                        >
                          <InstagramIcon />
                        </StyledSocialLink>
                      )}

                      {facebookUrl && (
                        <StyledSocialLink
                          href={facebookUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label={t('navigation.accessibility.facebook')}
                        >
                          <FacebookIcon />
                        </StyledSocialLink>
                      )}
                    </Box>
                  )}
                </StyledStoreLinkGrid>
              )}
            </Grid>
          </StyledSection>
        )
      }}
    </StaticQuery>
  )
}

export default Footer

import {SvgIcon, SvgIconProps} from '@mui/material'
import * as React from 'react'

export const PhoneIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M15.3094 18.6407C11.1442 16.9749 7.76494 13.7927 5.84739 9.73833L5.35229 8.69331L7.85139 6.19468C8.18931 5.85681 8.18931 5.31466 7.85139 4.97679L4.14204 1.25242C3.79625 0.91456 3.24613 0.91456 2.9082 1.26028C2.88463 1.28385 2.86105 1.30743 2.83748 1.33886C-0.494653 5.60538 0.794189 12.8341 5.98099 18.0199C11.1678 23.2058 18.3979 24.4944 22.6652 21.1629C23.0424 20.8643 23.1132 20.3143 22.8145 19.9371C22.791 19.9136 22.7674 19.8821 22.7438 19.8586L19.0266 16.142C18.6887 15.8042 18.1464 15.8042 17.8085 16.142L15.3094 18.6407Z" />
  </SvgIcon>
)

export default PhoneIcon

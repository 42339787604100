import {createTheme} from '@mui/material'
import commonTheme from '../common'

export const darkTheme = createTheme({
  ...commonTheme,
  palette: {
    ...commonTheme.palette,
    mode: 'dark',
    text: {
      disabled: '#4E536D',
    },
    common: {
      black: '#222230',
    },
    background: {
      default: '#4E536D',
      paper: '#46444F',
    },
    divider: '#FFFFFF',
  },
})

export default darkTheme

import {GlobalStyles} from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import {ThemeProvider} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import {Helmet} from 'gatsby-plugin-react-i18next'
import * as React from 'react'
import {
  AlertProvider,
  PasswordProvider,
  SectionIndicatorProvider,
} from '../../src/context'
import {darkTheme, lightTheme} from '../../src/theme'

export const TopLayout: React.FC = ({children}) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

  const theme = React.useMemo(
    () => (prefersDarkMode ? darkTheme : lightTheme),
    [prefersDarkMode],
  )

  return (
    <SectionIndicatorProvider>
      <AlertProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyles
            styles={{
              html: {
                overflowX: 'hidden',
                minHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
              },
              body: {
                display: 'flex',
                flex: '1 1 auto',
                overflowX: 'hidden',
                flexDirection: 'column',
              },
              '#___gatsby': {
                position: 'relative',
                display: 'flex',
                flex: '1 1 auto',
                minHeight: '100%',
                flexDirection: 'column',
                '& > div:not(.alert):not(.cookie-consent-banner)': {
                  display: 'flex',
                  flex: '1 1 auto',
                  minHeight: '100%',
                  flexDirection: 'column',
                },
              },
              table: {
                width: '100%',
                textAlign: 'left',
                borderCollapse: 'collapse',
                '& th': {
                  border: '1px solid lightgrey',
                  padding: '4px 8px',
                },
                '& td': {
                  border: '1px solid lightgrey',
                  padding: '4px 8px',
                },
              },
            }}
          />

          <PasswordProvider>{children}</PasswordProvider>

          <Helmet>
            <meta
              name="viewport"
              content="minimum-scale=1, initial-scale=1, width=device-width"
            />
          </Helmet>
        </ThemeProvider>
      </AlertProvider>
    </SectionIndicatorProvider>
  )
}

export default TopLayout
